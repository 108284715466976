/** @jsxImportSource @emotion/react */

import { Fragment, useEffect, useState } from 'react'
import 'twin.macro'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { convertDistance, getDistance } from 'geolib'

import Image from './Image'

dayjs.extend(relativeTime)

const Store = ({ sessionLocation, value }) => {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const [distance, setDistance] = useState(0)

  useEffect(() => {
    if (value) {
      fetch(`${process.env.REACT_APP_API_URL}/stores/byID/${value}`).then(
        async response => {
          const results = await response.json()

          setData(results)

          setLoading(false)
        },
      )
    }
  }, [value])

  useEffect(() => {
    if (data.latLng) {
      const dist = getDistance(
        { latitude: data.latLng.latitude, longitude: data.latLng.longitude },
        {
          latitude: sessionLocation.value.latitude,
          longitude: sessionLocation.value.longitude,
        },
      )

      setDistance(Math.round(convertDistance(dist, 'mi')))
    }
  }, [data, sessionLocation.value])

  return (
    <div tw="w-full">
      {loading ? (
        <div tw="animate-pulse max-w-2xl w-full">
          <div tw="mb-4 h-16 w-16 rounded-full bg-gray-200"></div>
          <div tw="h-6 w-1/2 rounded bg-gray-200"></div>
          <div tw="mt-1 h-4 w-1/4 rounded bg-gray-200"></div>
          <div tw="mt-2 h-4 w-1/4 rounded bg-gray-200"></div>
        </div>
      ) : (
        <Fragment>
          <div tw="mb-4">
            <Image
              alt={`${data.name} logo`}
              photo={data.photo}
              src={data.fileName}
              tw="w-16 shadow-md"
            />
          </div>
          {data.name && (
            <h1 tw="text-3xl font-bold tracking-tight">{data.name}</h1>
          )}
          {data && (
            <Fragment>
              <p tw="text-lg text-gray-600">
                {data.city}, {data.state} • {distance} miles away
              </p>
              <p tw="mt-2 text-sm text-gray-500">
                Last updated: <em>{dayjs(data.lastProductUpdate).fromNow()}</em>
              </p>
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  )
}

export default Store

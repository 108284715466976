/** @jsxImportSource @emotion/react */

import { Fragment, useEffect, useState } from 'react'
import tw from 'twin.macro'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Toaster } from 'react-hot-toast'
import { useLocalStorage, useSessionStorage } from 'react-use'
import { SearchProvider } from '@elastic/react-search-ui'
import queryString from '@elastic/search-ui/lib/esm/queryString'

import AgeConfirmation from './AgeConfirmation'
import AutoCompleteRedirect from './AutoCompleteRedirect'
import Home from './Home'
import Search from './Search'

import { connector } from '../connector'

const App = () => {
  const [loading, setLoading] = useState(true)
  const [ageConfirmation] = useLocalStorage('fj-ageConfirmation')
  const [sessionLocation, setSessionLocation] = useSessionStorage('fj-location')
  const location = useLocation()

  const config = {
    alwaysSearchOnInitialLoad: false,
    apiConnector: connector,
    hasA11yNotifications: true,
    trackUrlState: false,
    onAutocomplete: async state => {
      if (sessionLocation) {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/suggestions?q=${state.searchTerm}&lat=${sessionLocation.value.latitude}&lng=${sessionLocation.value.longitude}`,
          { headers: { 'Content-Type': 'application/json' } },
        )

        const results = await response.json()

        return results
      }
    },
  }

  useEffect(() => {
    if (!sessionLocation) {
      const { filters = [] } = queryString.parse(location.search)

      const match = filters.find(f => f.field === 'latLng')

      fetch(`${process.env.REACT_APP_API_URL}/geolocate`).then(
        async response => {
          const results = await response.json()

          setSessionLocation(
            match
              ? match.values[0]
              : {
                  label:
                    results.city && results.region
                      ? `${results.city}, ${results.region}`
                      : 'Unknown location',
                  value: { latitude: results.ll[0], longitude: results.ll[1] },
                },
          )

          setLoading(false)
        },
      )
    } else {
      setLoading(false)
    }
  }, [location.search, setSessionLocation, sessionLocation])

  return (
    <Fragment>
      <Helmet
        defaultTitle={process.env.REACT_APP_META_TITLE}
        titleTemplate={`${process.env.REACT_APP_META_TITLE} – %s`}
      >
        <title>{process.env.REACT_APP_META_DESC}</title>
      </Helmet>
      <Switch>
        <Route
          exact
          path="/"
          component={props => (
            <SearchProvider config={config}>
              <Home
                {...props}
                loading={loading}
                sessionLocation={sessionLocation}
                setSessionLocation={setSessionLocation}
              />
            </SearchProvider>
          )}
        />
        <Route
          path="/search"
          component={props => (
            <Search
              {...props}
              config={config}
              sessionLocation={sessionLocation}
              setSessionLocation={setSessionLocation}
            />
          )}
        />
        <Route path="/browse/:key/:value" component={AutoCompleteRedirect} />
        <Route path="*" element={<Redirect to="/" />} />
      </Switch>
      <Toaster toastOptions={{ duration: 3000 }} />
      {!ageConfirmation && (
        <AgeConfirmation
          center
          closeOnEsc={false}
          closeOnOverlayClick={false}
          showCloseIcon={false}
          styles={{
            modalContainer: tw`p-4 m-0`,
            modal: tw`rounded-md m-0 p-8 max-w-md w-full`,
          }}
        />
      )}
    </Fragment>
  )
}

export default App

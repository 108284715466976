/** @jsxImportSource @emotion/react */

import { Fragment, useEffect } from 'react'
import tw from 'twin.macro'
import { SearchBox, withSearch } from '@elastic/react-search-ui'
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from 'react-google-places-autocomplete'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import {
  MagnifyingGlassIcon as SmallMagnifyingGlassIcon,
  TagIcon,
} from '@heroicons/react/20/solid'

import Button from './Button'
import GeoLocate from './GeoLocate'
import Image from './Image'

const AutoComplete = ({
  collapseOnMobile = false,
  sessionLocation,
  setFilter,
  setSearchTerm,
  onSelectAutocomplete,
  setSessionLocation,
  onSubmit,
  clearFilters,
  placeholder,
  loading,
}) => {
  const onChange = async data => {
    if (data.value.place_id) {
      const results = await geocodeByPlaceId(data.value.place_id)
      const { lat: latitude, lng: longitude } = await getLatLng(results[0])

      setSessionLocation({
        label: results[0].formatted_address,
        value: { latitude, longitude },
      })
    }
  }

  const handleDefaultSelectAutocomplete = selection => {
    if (selection.suggestion) {
      setSearchTerm(selection.suggestion, { shouldClearFilters: false })
    } else {
      clearFilters(['latLng', 'radius'])
      setSearchTerm('', { shouldClearFilters: false })

      if (selection.field === 'storeUUID') {
        setFilter(`radius`, process.env.REACT_APP_AUTOCOMPLETE_RADIUS)
      }

      setFilter(selection.field, selection.value)
    }
  }

  const handleDefaultSubmit = data => {
    setSearchTerm(data, { shouldClearFilters: false })
  }

  useEffect(() => {
    if (sessionLocation) {
      setFilter('latLng', sessionLocation)
    }
  }, [sessionLocation, setFilter])

  useEffect(() => {
    setSearchTerm('', {
      autocompleteResults: true,
      autocompleteSuggestions: true,
      shouldClearFilters: false,
    })
  }, [setSearchTerm])

  const AutoCompleteView = ({
    autocompletedSuggestions,
    autocompletedResults,
    getItemProps,
  }) => (
    <div className="sui-search-box__autocomplete-container">
      {autocompletedResults.length > 0 && (
        <Fragment>
          <ul>
            {autocompletedResults.map(result => {
              if (result.field === 'storeUUID') {
                return (
                  <li
                    {...getItemProps({
                      key: (result.value || {}).id || result.value,
                      item: result,
                    })}
                  >
                    <div tw="flex items-center space-x-2">
                      <div tw="w-6 flex-shrink-0">
                        <Image
                          rounded
                          alt={`${result.name} logo`}
                          photo={result.photo}
                          src={result.fileName}
                          tw="h-6 w-6"
                        />
                      </div>
                      <div tw="min-w-0 flex-1">
                        <p tw="truncate text-sm font-medium text-gray-700">
                          {result.label}
                        </p>
                        <p tw="text-xs text-gray-500">
                          {result.city}, {result.state} • {result.distance}{' '}
                          miles
                        </p>
                      </div>
                    </div>
                  </li>
                )
              }

              if (['category', 'strain'].includes(result.field)) {
                return (
                  <li
                    {...getItemProps({
                      key: (result.value || {}).id || result.value,
                      item: result,
                    })}
                    tw="text-xs text-gray-500 cursor-default truncate"
                  >
                    <div tw="flex items-center space-x-2">
                      <div tw="w-6 flex-shrink-0 flex items-center justify-center">
                        <TagIcon
                          tw="h-4 w-4 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div>{result.label}</div>
                    </div>
                  </li>
                )
              }

              return (
                <li
                  {...getItemProps({
                    key: result.value,
                    item: result,
                  })}
                  tw="text-xs text-gray-500 cursor-default truncate"
                >
                  <div tw="flex items-center space-x-2">
                    <div tw="w-6 flex-shrink-0 flex items-center justify-center">
                      <SmallMagnifyingGlassIcon
                        tw="h-4 w-4 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div>{result.label}</div>
                  </div>
                </li>
              )
            })}
          </ul>
        </Fragment>
      )}
      {autocompletedSuggestions.products &&
        autocompletedSuggestions.products.length > 0 && (
          <Fragment>
            <ul>
              {autocompletedSuggestions.products.map((result, i) => (
                <li
                  {...getItemProps({
                    key: result.suggestion,
                    item: result,
                  })}
                  tw="text-xs text-gray-500 cursor-default"
                >
                  <div tw="flex items-center space-x-2">
                    <div tw="w-6 flex-shrink-0 flex items-center justify-center">
                      <SmallMagnifyingGlassIcon
                        tw="h-4 w-4 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <p tw="truncate">{result.suggestion}</p>
                  </div>
                </li>
              ))}
            </ul>
          </Fragment>
        )}
    </div>
  )

  const InputView = ({
    closeMenu,
    getAutocomplete,
    getButtonProps,
    getInputProps,
    openMenu,
  }) => (
    <div tw="space-y-2 desktop:(space-y-0 flex items-center justify-center rounded-full shadow-md)">
      <div css={[tw`w-full desktop:w-2/5`]}>
        <div tw="relative">
          <div tw="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-6 desktop:pl-4">
            <MagnifyingGlassIcon
              tw="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            {...getInputProps({
              autoCapitalize: 'off',
              autoComplete: 'off',
              css: [
                tw`border border-solid border-gray-300 py-4 pl-12 pr-4 w-full rounded-full desktop:(rounded-none rounded-l-full)`,
              ],
              onFocus: () => openMenu(),
              placeholder: placeholder || 'Search...',
              type: 'text',
            })}
          />
          {getAutocomplete()}
        </div>
      </div>
      <div
        css={[
          tw`relative`,
          collapseOnMobile
            ? tw`hidden desktop:(block w-2/5)`
            : tw`desktop:w-2/5`,
        ]}
      >
        <GooglePlacesAutocomplete
          autocompletionRequest={{
            componentRestrictions: { country: ['us'] },
          }}
          selectProps={{
            components: { DropdownIndicator: null, LoadingIndicator: null },
            backspaceRemovesValue: true,
            blurInputOnSelect: true,
            className: 'rgpa-container',
            classNamePrefix: 'rgpa',
            noOptionsMessage: () => null,
            onChange,
            onFocus: () => closeMenu(),
            openMenuOnClick: false,
            openMenuOnFocus: false,
            placeholder: loading ? 'Loading...' : 'Select...',
            styles: { input: () => tw`m-0 p-0` },
            value: sessionLocation,
          }}
        />
        <div tw="absolute inset-y-0 right-0 flex items-center pl-4 pr-6 my-2 border-l border-gray-300 desktop:(px-4)">
          <GeoLocate
            loading={loading}
            setSessionLocation={setSessionLocation}
          />
        </div>
      </div>
      <div
        css={[
          collapseOnMobile
            ? tw`sr-only desktop:(not-sr-only	w-1/5)`
            : tw`desktop:w-1/5`,
        ]}
      >
        <Button
          {...getButtonProps({
            label: 'Search',
            css: [
              tw`text-xl leading-6 rounded-full desktop:(rounded-none rounded-r-full w-full border-primary)`,
            ],
          })}
        />
      </div>
    </div>
  )

  return (
    <SearchBox
      autocompleteResults={{
        shouldTrackClickThrough: false,
        titleField: 'label',
        urlField: 'url',
      }}
      autocompleteSuggestions={true}
      autocompleteView={AutoCompleteView}
      inputView={InputView}
      onSelectAutocomplete={
        onSelectAutocomplete || handleDefaultSelectAutocomplete
      }
      onSubmit={onSubmit || handleDefaultSubmit}
      shouldClearFilters={false}
    />
  )
}

export default withSearch(({ setFilter, setSearchTerm }) => ({
  setFilter,
  setSearchTerm,
}))(AutoComplete)

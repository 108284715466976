/** @jsxImportSource @emotion/react */

import { Fragment } from 'react'
import tw from 'twin.macro'

const Image = props => (
  <Fragment>
    {props.src ? (
      <img
        {...props}
        css={[props.rounded && tw`rounded-full`]}
        src={
          process.env.REACT_APP_ENV === 'development'
            ? props.photo
            : `${process.env.REACT_APP_FB_STORAGE_URL}/${props.src}`
        }
        alt={props.alt || ''}
      />
    ) : (
      <div
        {...props}
        css={[
          props.rounded && tw`rounded-full`,
          tw`bg-gray-100 flex items-center justify-center shadow-md`,
        ]}
      >
        <img
          {...props}
          css={[props.rounded && tw`rounded-full`]}
          tw="w-1/2 rounded-full"
          src="favicon.svg"
          alt=""
        />
      </div>
    )}
  </Fragment>
)

export default Image

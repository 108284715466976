/** @jsxImportSource @emotion/react */

import { Fragment } from 'react'
import tw from 'twin.macro'
import { Menu } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

import Transition from './Transition'

export const radiusOptions = [
  {
    name: '5 miles',
    value: 5,
  },
  {
    name: '10 miles',
    value: 10,
  },
  {
    name: '25 miles',
    value: 25,
  },
  {
    name: '50 miles',
    value: 50,
  },
  {
    name: '100 miles',
    value: 100,
  },
  {
    name: '250 miles',
    value: 250,
  },
]

const Radius = props => (
  <Menu as="div" tw="relative inline-block">
    <Menu.Button
      {...props}
      className="group"
      css={[
        props.outline &&
          tw`border border-gray-300 rounded-full py-2 px-4 desktop:shadow`,
        tw`whitespace-nowrap inline-flex justify-center font-medium text-gray-600 bg-white hover:text-gray-700`,
      ]}
    >
      {props.filters &&
      props.filters.length &&
      props.filters.find(f => f.field === 'radius')
        ? `${props.filters.find(f => f.field === 'radius').values[0]} miles`
        : 'Distance'}{' '}
      <ChevronDownIcon
        tw="-mr-1 ml-1 mt-px h-4 w-4 tablet:(h-5 w-5) flex-shrink-0 text-gray-400 group-hover:text-gray-500"
        aria-hidden="true"
      />
    </Menu.Button>

    <Transition
      as={Fragment}
      enter={tw`transition ease-out duration-100`}
      enterFrom={tw`transform opacity-0 scale-95`}
      enterTo={tw`transform opacity-100 scale-100`}
      leave={tw`transition ease-in duration-75`}
      leaveFrom={tw`transform opacity-100 scale-100`}
      leaveTo={tw`transform opacity-0 scale-95`}
    >
      <Menu.Items tw="absolute right-0 z-20 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div tw="py-1">
          {radiusOptions.map(option => (
            <Menu.Item key={option.name}>
              {({ active }) => (
                <button
                  css={[
                    ((props.filters &&
                      props.filters.length &&
                      props.filters.find(f => f.field === 'radius') &&
                      props.filters.find(f => f.field === 'radius')
                        .values[0]) ||
                      Number(process.env.REACT_APP_SEARCH_RADIUS)) ===
                    option.value
                      ? tw`font-medium text-gray-900`
                      : tw`text-gray-500`,
                    active ? tw`bg-gray-100` : '',
                    tw`w-full text-left block px-4 py-2 text-xs tablet:text-sm`,
                  ]}
                  onClick={() => props.setFilter('radius', option.value)}
                >
                  {option.name}
                </button>
              )}
            </Menu.Item>
          ))}
        </div>
      </Menu.Items>
    </Transition>
  </Menu>
)

export default Radius

/** @jsxImportSource @emotion/react */

import { Fragment } from 'react'
import tw, { styled } from 'twin.macro'

const StyledButton = styled.button(({ outline }) => [
  tw`flex items-center justify-center w-full font-bold rounded-md py-4 px-6 border border-transparent bg-primary text-white hocus:(outline-none bg-green-900) desktop:w-auto`,
  outline &&
    tw`border-2 bg-transparent border-gray-300 font-medium text-gray-600 hocus:bg-gray-100`,
])

const Button = props => (
  <StyledButton {...props}>
    {props.isLoading ? (
      <Fragment>
        <svg
          tw="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            tw="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            tw="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        {props.loadingMessage || 'Please wait'}
      </Fragment>
    ) : (
      props.children || props.label || 'Submit'
    )}
  </StyledButton>
)

export default Button

/** @jsxImportSource @emotion/react */

import { Fragment } from 'react'
import tw from 'twin.macro'
import { XMarkIcon } from '@heroicons/react/24/solid'
import toast from 'react-hot-toast'

import Icon from './Icon'
import Transition from './Transition'

const Toast = ({ title, content, id, name, visible }) => (
  <Transition
    as={Fragment}
    show={visible}
    enter={tw`transform ease-out duration-300 transition`}
    enterFrom={tw`translate-y-2 opacity-0 tablet:translate-y-0 tablet:translate-x-2`}
    enterTo={tw`translate-y-0 opacity-100 tablet:translate-x-0`}
    leave={tw`transition ease-in duration-100`}
    leaveFrom={tw`opacity-100`}
    leaveTo={tw`opacity-0`}
  >
    <div tw="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
      <div tw="p-4">
        <div tw="flex items-start">
          <div tw="flex-shrink-0">
            <Icon name={name} tw="h-6 w-6" aria-hidden="true" />
          </div>
          <div tw="ml-3 w-0 flex-1 pt-0.5">
            {title && <p tw="text-sm font-medium">{title}</p>}
            {content && <p tw="mt-1 text-sm">{content}</p>}
          </div>
          <div tw="ml-4 flex flex-shrink-0">
            <button
              tw="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              onClick={() => toast.dismiss(id)}
            >
              <span tw="sr-only">Close</span>
              <XMarkIcon tw="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
)

export default Toast

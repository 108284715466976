/** @jsxImportSource @emotion/react */

import tw from 'twin.macro'

const Footer = ({ light = false }) => (
  <footer tw="container overflow-hidden py-16 text-sm">
    <nav tw="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
      <div tw="px-5 py-2">
        <a
          href="mailto:hi@freshjane.com"
          css={[
            tw`hover:underline`,
            light
              ? tw`text-gray-500 hover:text-gray-600`
              : tw`text-green-100 hover:text-green-50`,
          ]}
        >
          Contact
        </a>
      </div>
      <div tw="px-5 py-2">
        <a
          href="https://freshjane.statuspage.io"
          css={[
            tw`hover:underline`,
            light
              ? tw`text-gray-500 hover:text-gray-600`
              : tw`text-green-100 hover:text-green-50`,
          ]}
        >
          Status
        </a>
      </div>
      <div tw="px-5 py-2">
        <a
          href="/privacy.html"
          css={[
            tw`hover:underline`,
            light
              ? tw`text-gray-500 hover:text-gray-600`
              : tw`text-green-100 hover:text-green-50`,
          ]}
        >
          Privacy
        </a>
      </div>
    </nav>
    <p
      css={[
        tw`mt-8 text-center`,
        light ? tw`text-gray-500` : tw`text-green-100`,
      ]}
    >
      &copy; 2022 FreshJane. All rights reserved.
    </p>
  </footer>
)

export default Footer

/** @jsxImportSource @emotion/react */

import { useState } from 'react'
import tw from 'twin.macro'
import { Badge } from '@tremor/react'
import {
  BuildingStorefrontIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline'
import { Disclosure } from '@headlessui/react'

import Details from './Details'
import Image from './Image'

const Result = ({ clearFilters, result, setFilter, setSearchTerm }) => {
  const [activeWeightIdx, setActiveWeightIdx] = useState(0)

  return (
    <Disclosure as="li">
      {({ open }) => (
        <div
          css={[
            tw`relative mx-4 py-4 desktop:(mx-6 py-6)`,
            open &&
              tw`bg-white rounded-xl shadow-2xl mb-4 mx-0 px-4 desktop:(mb-6 mx-0 px-6)`,
          ]}
        >
          <div tw="flex items-center gap-4">
            <div tw="relative">
              {result.status.raw === 'new' && (
                <div tw="absolute bottom-0 left-0">
                  <Badge text="New" color="green" />
                </div>
              )}
              {result.status.raw === 'restocked' && (
                <div tw="absolute bottom-0 left-0">
                  <Badge text="Restock" color="blue" />
                </div>
              )}
              <Image
                rounded
                src={result.fileName.raw}
                photo={result.photo.raw}
                alt={`${result.name.raw} logo`}
                tw="flex-shrink-0 object-cover h-16 w-16 rounded-full shadow-md tablet:(h-20 w-20)"
              />
            </div>
            <div tw="flex-1 min-w-0">
              {result.brand.raw && (
                <button
                  onClick={() => {
                    clearFilters(['latLng', 'radius'])
                    setSearchTerm(result.brand.raw, {
                      shouldClearFilters: false,
                    })
                  }}
                  tw="block text-xs text-left text-gray-500 p-0 m-0 relative z-10 hover:underline"
                >
                  {result.brand.raw}
                </button>
              )}
              <a
                href={result.url.raw}
                tw="font-bold text-primary leading-none! relative z-10 hover:underline tablet:text-lg"
              >
                {result.name.raw}
              </a>
              <button
                onClick={() => {
                  clearFilters(['latLng', 'radius'])
                  setSearchTerm('', { shouldClearFilters: false })
                  setFilter('storeUUID', result.storeUUID.raw[0])
                }}
                tw="w-full tablet:w-auto flex items-start text-sm text-left text-gray-500 relative z-10 hover:underline"
              >
                <BuildingStorefrontIcon
                  tw="mr-1 h-4 w-4 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <div tw="truncate">{result.storeName.raw}</div>
              </button>
              <div tw="flex items-center">
                {result.recPrices.raw && (
                  <div tw="flex-shrink-0 flex items-center h-8">
                    <span tw="block text-gray-700 font-semibold truncate w-[4.5rem] tablet:text-xl">
                      {result.recPrices.raw[activeWeightIdx]}
                    </span>
                  </div>
                )}
                <ul tw="ml-1 truncate">
                  {result.weight.raw &&
                  result.weight.raw.length > 1 &&
                  result.weight.raw[activeWeightIdx].toLowerCase() !== 'n/a' ? (
                    result.weight.raw.map((weight, i) => (
                      <li key={weight} tw="inline-flex relative z-10">
                        <button
                          css={[
                            i === activeWeightIdx
                              ? tw`text-gray-700 font-semibold ring-2 ring-gray-600 focus:(ring-gray-600)`
                              : tw`ring-1 ring-gray-400 text-gray-600 focus:(ring-1)`,
                            tw`inline-flex bg-white items-center rounded-full px-2.5 py-0.5 text-xs mr-1 outline-none ring-inset`,
                          ]}
                          onClick={() => {
                            setActiveWeightIdx(i)
                          }}
                        >
                          {weight}
                        </button>
                      </li>
                    ))
                  ) : (
                    <li tw="inline-flex">
                      <span tw="inline-flex bg-white items-center rounded-full px-2.5 py-0.5 text-xs mr-1 outline-none ring-inset text-gray-700 font-semibold ring-2 ring-gray-600 focus:(ring-gray-600)">
                        {result.weight.raw ? result.weight.raw[0] : 'each'}
                      </span>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <Disclosure.Button tw="flex-shrink-0 rounded-full p-2 hover:bg-gray-100 active:bg-gray-200">
              {open ? (
                <ChevronUpIcon tw="h-5 w-5 text-gray-700" aria-hidden="true" />
              ) : (
                <ChevronDownIcon
                  tw="h-5 w-5 text-gray-700"
                  aria-hidden="true"
                />
              )}
              <div tw="absolute inset-0" />
            </Disclosure.Button>
          </div>
          <Disclosure.Panel tw="mt-6">
            <Details
              activePriceIdx={activeWeightIdx}
              clearFilters={clearFilters}
              setSearchTerm={setSearchTerm}
              result={result}
              setFilter={setFilter}
            />
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  )
}

export default Result

/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react'
import 'twin.macro'
import { ArrowPathIcon, FaceFrownIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

import Button from './Button'

import { radiusOptions } from './Radius'

export default function NoResults({ radius, setFilter }) {
  const [nextRadius, setNextRadius] = useState()
  const [noResults, setNoResults] = useState(false)

  const handleClick = () => {
    setFilter('radius', nextRadius)
  }

  useEffect(() => {
    const current = radiusOptions.findIndex(opt => opt.value === radius)

    if (current < radiusOptions.length - 1) {
      setNextRadius(radiusOptions[current + 1].value)
    } else {
      setNoResults(true)
    }
  }, [radius, setFilter])

  return (
    <div tw="flex flex-col items-center text-center py-8">
      <FaceFrownIcon tw="h-16 w-16 text-gray-400" />
      <h1 tw="mt-2 text-3xl font-bold tracking-tight text-gray-700 tablet:text-4xl">
        Bummer, no results
      </h1>
      <p tw="mt-2 text-base text-gray-500">
        Try expanding your search radius or using a less specific search
        criteria
      </p>
      <div tw="mt-8 mx-auto">
        {!noResults ? (
          <Button tw="rounded-full font-normal shadow-md" onClick={handleClick}>
            Expand search to{' '}
            <span tw="inline font-semibold mx-1">{nextRadius}</span> miles{' '}
            <span tw="inline ml-2" aria-hidden="true">
              {' '}
              &rarr;
            </span>
          </Button>
        ) : (
          <Link to="/">
            <Button tw="rounded-full shadow-md" outline>
              Restart your search
              <ArrowPathIcon tw="-mr-1 ml-3 h-5 w-5" aria-hidden="true" />
            </Button>
          </Link>
        )}
      </div>
    </div>
  )
}

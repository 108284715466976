/** @jsxImportSource @emotion/react */

import 'twin.macro'
import { Link } from 'react-router-dom'
import { ArrowPathIcon } from '@heroicons/react/24/solid'

import AutoComplete from './AutoComplete'

const NavBar = ({
  clearFilters,
  setFilter,
  sessionLocation,
  setSearchTerm,
  setSessionLocation,
}) => (
  <header tw="tablet:static tablet:overflow-y-visible bg-secondary shadow-md">
    <div tw="container">
      <div tw="relative flex items-center justify-between gap-4 desktop:gap-8">
        <div tw="absolute inset-y-0 left-0 tablet:static flex">
          <div tw="flex items-center flex-shrink-0">
            <Link
              to="/"
              tw="flex items-center font-heading font-bold text-4xl text-white tracking-tight !no-underline"
            >
              <img
                src="/favicon.svg"
                alt="Shopping bags"
                tw="h-12 tablet:h-8 w-auto mr-1"
              />
              <span tw="hidden tablet:block">
                Fresh<span tw="text-primary">Jane</span>
              </span>
            </Link>
          </div>
        </div>
        <div tw="pl-16 py-8 tablet:pl-0 min-w-0 flex-1">
          <AutoComplete
            clearFilters={clearFilters}
            collapseOnMobile={true}
            sessionLocation={sessionLocation}
            setSessionLocation={setSessionLocation}
            setSearchTerm={setSearchTerm}
            setFilter={setFilter}
          />
        </div>
        <div tw="desktop:hidden">
          <Link to="/" className="group" tw="text-green-100">
            <span tw="block rounded-full bg-green-600 p-2 mx-auto group-hover:(bg-green-700 text-green-50)">
              <ArrowPathIcon tw="h-5 w-5" aria-hidden="true" />
            </span>
            <span tw="sr-only">Restart search</span>
          </Link>
        </div>
      </div>
    </div>
  </header>
)

export default NavBar

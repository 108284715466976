/** @jsxImportSource @emotion/react */

import { Fragment, useState } from 'react'
import tw from 'twin.macro'
import { useLocation } from 'react-router-dom'
import {
  Paging,
  PagingInfo,
  Results,
  SearchProvider,
  WithSearch,
} from '@elastic/react-search-ui'
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline'

import Filters from './Filters'
import Footer from './Footer'
import Loading from './Loading'
import NavBar from './NavBar'
import NoResults from './NoResults'
import Radius from './Radius'
import Result from './Result'
import Sort from './Sort'
import Store from './Store'

const Search = ({ config, sessionLocation, setSessionLocation }) => {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)

  const location = useLocation()

  return (
    <SearchProvider
      config={{
        ...config,
        alwaysSearchOnInitialLoad: true,
        initialState: {
          filters: [...((location.state || {}).filters || [])],
          ...((location.state || {}).searchTerm && {
            searchTerm: (location.state || {}).searchTerm,
          }),
          resultsPerPage: 10,
        },
        filters: [
          {
            field: 'latLng',
            values: [sessionLocation],
            type: 'all',
          },
        ],
        trackUrlState: true,
      }}
    >
      <WithSearch mapContextToProps={context => context}>
        {({
          addFilter,
          clearFilters,
          filters,
          removeFilter,
          resultSearchTerm,
          totalResults,
          setFilter,
          setSearchTerm,
          setSort,
          sortField,
          wasSearched,
        }) => {
          const radius =
            filters && filters.length && filters.find(f => f.field === 'radius')
              ? filters.find(f => f.field === 'radius').values[0]
              : Number(process.env.REACT_APP_SEARCH_RADIUS)

          const store =
            filters &&
            filters.length &&
            filters.find(f => f.field === 'storeUUID')

          const element = document.getElementById('top')

          if (element) element.scrollIntoView()

          return (
            <Fragment>
              <div tw="flex flex-col min-h-full" id="top">
                <div tw="flex-shrink-0">
                  <NavBar
                    clearFilters={clearFilters}
                    sessionLocation={sessionLocation}
                    setFilter={setFilter}
                    setSearchTerm={setSearchTerm}
                    setSessionLocation={setSessionLocation}
                  />
                </div>
                <div tw="flex-grow bg-gradient-to-b from-white to-gray-50 desktop:(py-12)">
                  <main id="main-content">
                    {wasSearched ? (
                      <Fragment>
                        <div tw="bg-gray-100 border-b border-gray-200 mb-8 desktop:hidden">
                          <div tw="container">
                            <h2 tw="sr-only">Filters</h2>
                            <div tw="flex items-center justify-between w-full py-4 text-xs tablet:text-sm">
                              <button
                                className="group"
                                type="button"
                                tw="inline-flex justify-center font-medium text-gray-600 bg-white border border-gray-300 rounded-full py-2 px-4 hover:text-gray-700"
                                onClick={() => setMobileFiltersOpen(true)}
                              >
                                <AdjustmentsHorizontalIcon
                                  tw="-ml-1 mr-1 h-4 w-4 tablet:(h-5 w-5) flex-shrink-0 text-gray-500 group-hover:text-gray-600"
                                  aria-hidden="true"
                                />
                                Filters
                              </button>
                              <div tw="ml-2 inline-flex">
                                {(!store ||
                                  (store.values &&
                                    store.values.length !== 1)) && (
                                  <div tw="mr-2 inline-block">
                                    <Radius
                                      filters={filters}
                                      outline={true}
                                      setFilter={setFilter}
                                    />
                                  </div>
                                )}
                                <Sort
                                  setSort={setSort}
                                  sortField={sortField}
                                  outline
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div tw="container">
                          <div
                            css={[
                              tw`items-end justify-between border-b border-gray-200 pb-8`,
                              totalResults > 0
                                ? tw`flex`
                                : tw`hidden desktop:flex`,
                            ]}
                          >
                            {store &&
                            store.values &&
                            store.values.length === 1 ? (
                              <Store
                                sessionLocation={sessionLocation}
                                value={store.values[0]}
                              />
                            ) : (
                              <div>
                                <h1 tw="text-3xl text-gray-700 font-bold tracking-tight desktop:text-4xl">
                                  Found {totalResults.toLocaleString('en-US')}{' '}
                                  products
                                  {resultSearchTerm &&
                                    ` for "${resultSearchTerm}"`}
                                </h1>
                                <p tw="mt-4 text-lg text-gray-500">
                                  Searching within {radius} miles of{' '}
                                  {sessionLocation && sessionLocation.label}
                                </p>
                              </div>
                            )}
                            <div tw="hidden desktop:(flex flex-shrink-0 block justify-end space-x-2)">
                              {(!store ||
                                (store.values &&
                                  store.values.length !== 1)) && (
                                <Radius
                                  filters={filters}
                                  outline={true}
                                  setFilter={setFilter}
                                />
                              )}
                              <Sort
                                outline={true}
                                setSort={setSort}
                                sortField={sortField}
                              />
                            </div>
                          </div>
                          <div>
                            <div tw="pb-4 desktop:(flex py-8 divide-x divide-gray-200)">
                              <div tw="flex-shrink-0 desktop:(w-1/4 pr-8)">
                                <Filters
                                  addFilter={addFilter}
                                  clearFilters={clearFilters}
                                  filters={filters}
                                  removeFilter={removeFilter}
                                  mobileFiltersOpen={mobileFiltersOpen}
                                  setMobileFiltersOpen={setMobileFiltersOpen}
                                  radius={radius}
                                  sessionLocation={sessionLocation}
                                  setFilter={setFilter}
                                  setSearchTerm={setSearchTerm}
                                />
                              </div>
                              <section
                                aria-labelledby="product-heading"
                                tw="w-full desktop:(pl-2)"
                              >
                                <h2 id="product-heading" tw="sr-only">
                                  Products
                                </h2>
                                {totalResults > 0 ? (
                                  <Fragment>
                                    <PagingInfo
                                      view={({ start, end }) => (
                                        <div
                                          tw="text-sm mt-8 mb-4 ml-4 desktop:(mt-0 ml-6)"
                                          className="paging-info"
                                        >
                                          Showing{' '}
                                          <strong>
                                            {start} - {end}
                                          </strong>{' '}
                                          out of{' '}
                                          <strong>
                                            {totalResults.toLocaleString(
                                              'en-US',
                                            )}
                                          </strong>{' '}
                                          products
                                        </div>
                                      )}
                                    />
                                    <Results
                                      resultView={({ result }) => (
                                        <Result
                                          clearFilters={clearFilters}
                                          result={result}
                                          setFilter={setFilter}
                                          setSearchTerm={setSearchTerm}
                                        />
                                      )}
                                    />
                                  </Fragment>
                                ) : (
                                  <NoResults
                                    radius={radius}
                                    setFilter={setFilter}
                                  />
                                )}
                              </section>
                            </div>
                            <Paging tw="flex items-center justify-center py-8 border-t border-gray-200" />
                          </div>
                        </div>
                      </Fragment>
                    ) : (
                      <div tw="flex flex-col items-center justify-center py-24">
                        <Loading />
                      </div>
                    )}
                  </main>
                </div>
                <div tw="flex-shrink-0 bg-gradient-to-b from-gray-50 to-gray-100">
                  <Footer light={true} />
                </div>
              </div>
            </Fragment>
          )
        }}
      </WithSearch>
    </SearchProvider>
  )
}

export default Search

import { convertDistance, getDistance as getDist } from 'geolib'

const dataFormatter = number =>
  (number / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  })

const getDistance = (src, dest) => {
  const dist = getDist(src, dest)

  return Math.round(convertDistance(dist, 'mi'))
}

export { dataFormatter, getDistance }

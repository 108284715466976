/** @jsxImportSource @emotion/react */

import { Fragment } from 'react'
import { Global, css } from '@emotion/react'
import tw, { theme, GlobalStyles as BaseStyles } from 'twin.macro'

import '@fontsource/nunito'
import 'react-responsive-modal/styles.css'
import 'rc-pagination/assets/index.css'
import '@tremor/react/dist/esm/tremor.css'

const customStyles = css`
  body,
  html,
  #root {
    ${tw`h-full w-full overflow-x-hidden`}
  }

  body {
    ${tw`font-sans text-gray-700`}
  }

  a:focus {
    ${tw`ring ring-yellow-500 outline-none`}
  }

  h1,
  h2,
  h3 {
    ${tw`font-heading`}
  }

  [type='text']:focus,
  [type='email']:focus,
  [type='url']:focus,
  [type='password']:focus,
  [type='number']:focus,
  [type='date']:focus,
  [type='datetime-local']:focus,
  [type='month']:focus,
  [type='search']:focus,
  [type='tel']:focus,
  [type='time']:focus,
  [type='week']:focus,
  [multiple]:focus,
  textarea:focus,
  select:focus {
    ${tw`border-yellow-500 ring-2 ring-inset ring-yellow-500 outline-none`}
  }

  [type='checkbox'],
  [type='radio'] {
    ${tw`text-primary`}

    &:focus {
      ${tw`ring-yellow-500`}
    }
  }

  button:focus {
    ${tw`border-yellow-500 ring-2 ring-inset ring-yellow-500 outline-none`}
  }

  .sui-results-container {
    a[href^="http"]::after,
    a[href^="https://"]::after
    {
      content: '';
      ${tw`h-4 w-4 ml-1 text-primary`}
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23${theme(
        'colors.primary',
      ).replace(
        '#',
        '',
      )}'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25' /%3E%3C/svg%3E");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
    }
  }

  .sui-search-box__autocomplete-container {
    ${tw`inset-x-0 hidden flex flex-col absolute shadow-md border border-gray-300 m-0 bg-white rounded-md overflow-hidden z-20`}
    top: 110%;

    ul {
      ${tw`divide-y divide-gray-200`}

      &:first-of-type {
        ${tw`border-b border-gray-200`}
      }
    }

    li {
      ${tw`p-2`}

      &:hover,
      &[aria-selected='true'] {
        ${tw`bg-gray-50`}
      }
    }
  }

  .sui-search-box__text-input {
    ${tw`p-0 truncate`}
  }

  .sui-paging {
    ${tw`font-sans`}

    .rc-pagination-item {
      &:hover {
        ${tw`border-primary`}

        a {
          ${tw`text-primary`}
        }
      }

      &-active {
        ${tw`bg-primary border-primary text-white`}

        &:hover {
          a {
            ${tw`text-white`}
          }
        }
      }
    }

    .rc-pagination-prev,
    .rc-pagination-next {
      ${tw`bg-white`}
    }

    .rc-pagination-jump-prev:hover:after,
    .rc-pagination-jump-next:hover:after {
      ${tw`text-primary`}
    }
    .rc-pagination-jump-prev:hover:after {
      content: '•••';
    }
    .rc-pagination-jump-next:hover:after {
      content: '•••';
    }
  }

  .rgpa-container {
    * {
      transition: none !important;
    }

    .rgpa__control {
      ${tw`border-gray-300! rounded-full pr-20 pl-6 py-4 m-0 desktop:(rounded-none)`}

      &--is-focused {
        ${tw`(border-yellow-500 ring-2 ring-inset ring-yellow-500 outline-none)!`}

        .rgpa__single-value {
          ${tw`hidden`}
        }
      }

      .rgpa__input input {
        ${tw`ring-0`}
      }

      .rgpa__value-container {
        ${tw`p-0`}

        .rgpa__single-value {
          ${tw`relative m-0 translate-y-0`}
        }
      }
    }

    .rgpa__menu {
      ${tw`inset-x-0 hidden flex flex-col absolute shadow-md border border-gray-300 m-0 p-0 bg-white rounded-md overflow-hidden`}
      top: 110% !important;

      .rgpa__menu-list {
        ${tw`divide-y divide-gray-200 m-0 p-0`}
      }

      .rgpa__option {
        ${tw`text-xs text-gray-500 cursor-default text-xs m-0 p-2 truncate w-auto`}

        &--is-focused {
          ${tw`bg-gray-50`}
        }
      }
    }
  }
`

const GlobalStyles = () => (
  <Fragment>
    <BaseStyles />
    <Global styles={customStyles} />
  </Fragment>
)

export default GlobalStyles

/** @jsxImportSource @emotion/react */

import 'twin.macro'
import { Link, useHistory } from 'react-router-dom'
import { withSearch } from '@elastic/react-search-ui'

import AutoComplete from './AutoComplete'
import Footer from './Footer'

const Home = ({ loading, sessionLocation, setSessionLocation }) => {
  const history = useHistory()

  const onSubmit = async data => {
    history.push(`/search?q=${encodeURIComponent(data)}`)
  }

  const onSelectAutocomplete = selection => {
    if (selection.suggestion) {
      onSubmit(selection.suggestion)
    } else {
      const filters = [
        {
          field: selection.field,
          values: [selection.value],
        },
      ]

      if (selection.field === 'storeUUID') {
        filters.push({
          field: 'radius',
          values: [process.env.REACT_APP_AUTOCOMPLETE_RADIUS],
        })
      }

      history.push({
        pathname: '/search',
        state: {
          filters,
          searchTerm: '',
        },
      })
    }
  }

  return (
    <div tw="bg-gradient-to-b from-secondary to-green-600 flex flex-col min-h-full">
      <div tw="flex-grow flex items-center">
        <div tw="container py-12 tablet:(pt-12 pb-16)">
          <div tw="flex-shrink-0 flex items-center justify-center mb-8 tablet:mb-16">
            <Link to="/">
              <img
                src="/images/logo.svg"
                alt="FreshJane"
                tw="h-10 tablet:h-14 w-auto"
              />
            </Link>
          </div>
          <AutoComplete
            loading={loading}
            sessionLocation={sessionLocation}
            setSessionLocation={setSessionLocation}
            onSelectAutocomplete={onSelectAutocomplete}
            onSubmit={onSubmit}
            placeholder="Search dispensaries and products..."
          />
          <div tw="pt-8 tablet:pt-24">
            <h2 tw="text-center tablet:text-lg uppercase tracking-wide text-green-200 mb-8">
              Search over <span tw="font-semibold">3 million</span> cannabis
              products featured on
            </h2>
            <div tw="flex flex-wrap justify-evenly items-center gap-6 tablet:gap-8">
              <img tw="h-8 mt-[2px]" src="/images/dutchie.svg" alt="Dutchie" />
              <img tw="h-8" src="/images/iheartjane.svg" alt="iHeartJane" />
              <img tw="h-8 mt-[4px]" src="/images/leafly.svg" alt="Leafly" />
              <img tw="h-8" src="/images/sunnyside.svg" alt="Sunnyside" />
              <img
                tw="h-8 mt-[7px]"
                src="/images/weedmaps.svg"
                alt="Weedmaps"
              />
            </div>
          </div>
        </div>
      </div>
      <div tw="flex-shrink-0">
        <Footer />
      </div>
    </div>
  )
}

export default withSearch(({ setFilter, filters }) => ({
  setFilter,
  filters,
}))(Home)

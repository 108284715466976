class APIConnector {
  async onSearch({ searchTerm, current, filters, sortField }) {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/products/search`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          searchTerm,
          current,
          filters,
          sortField,
        }),
      },
    )

    const results = await response.json()

    return results
  }
  async onAutocomplete() {}
}

const connector = new APIConnector()

export { connector }

/** @jsxImportSource @emotion/react */

import { Fragment, useEffect, useState } from 'react'
import tw from 'twin.macro'
import { Dialog, Disclosure } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon, MinusIcon, PlusIcon } from '@heroicons/react/20/solid'

import Image from './Image'
import Transition from './Transition'

const Filters = ({
  addFilter,
  clearFilters,
  filters,
  radius,
  removeFilter,
  mobileFiltersOpen,
  setMobileFiltersOpen,
  sessionLocation,
  setSearchTerm,
  setFilter,
}) => {
  const [stores, setStores] = useState([])

  const categories = [
    { label: 'Concentrate', value: 'concentrate' },
    { label: 'Edibles', value: 'edibles' },
    { label: 'Flower', value: 'flower' },
    { label: 'Pre-rolls', value: 'pre-rolls' },
    { label: 'Tinctures', value: 'tinctures' },
    { label: 'Topicals', value: 'topicals' },
    { label: 'Vape', value: 'vape' },
  ]

  const baseFilters = [
    {
      id: 'strain',
      name: 'Types',
      options: [
        { label: 'Indica', value: 'indica' },
        { label: 'Sativa', value: 'sativa' },
        { label: 'Hybrid', value: 'hybrid' },
      ],
    },
    {
      id: 'options',
      name: 'Options',
      options: [
        { label: 'Medical only', value: 'med' },
        { label: 'Include out of stock', value: 'oos' },
      ],
    },
  ]

  const handleFilterChange = event => {
    const target = event.target

    if (target.checked) {
      addFilter(target.name, target.value)
    } else {
      removeFilter(target.name, target.value)
    }
  }

  const handleFilterClick = storeUUID => {
    clearFilters(['latLng', 'radius'])
    setSearchTerm('', { shouldClearFilters: false })
    setFilter(`radius`, process.env.REACT_APP_AUTOCOMPLETE_RADIUS)
    setFilter('storeUUID', storeUUID)
    setMobileFiltersOpen(false)
  }

  useEffect(() => {
    if (sessionLocation) {
      fetch(
        `${process.env.REACT_APP_API_URL}/suggestions?lat=${sessionLocation.value.latitude}&lng=${sessionLocation.value.longitude}&radius=${radius}`,
        { headers: { 'Content-Type': 'application/json' } },
      )
        .then(response => response.json())
        .then(data => {
          setStores(data.autocompletedResults)
        })
    }
  }, [radius, sessionLocation])

  return (
    <Fragment>
      <Transition show={mobileFiltersOpen} as={Fragment}>
        <Dialog
          as="div"
          tw="desktop:hidden relative z-40"
          onClose={setMobileFiltersOpen}
        >
          <Transition.Child
            as={Fragment}
            enter={tw`transition-opacity ease-linear duration-300`}
            enterFrom={tw`opacity-0`}
            enterTo={tw`opacity-100`}
            leave={tw`transition-opacity ease-linear duration-300`}
            leaveFrom={tw`opacity-100`}
            leaveTo={tw`opacity-0`}
          >
            <div tw="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div tw="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter={tw`transition ease-in-out duration-300 transform`}
              enterFrom={tw`translate-x-full`}
              enterTo={tw`translate-x-0`}
              leave={tw`transition ease-in-out duration-300 transform`}
              leaveFrom={tw`translate-x-0`}
              leaveTo={tw`translate-x-full`}
            >
              <Dialog.Panel tw="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl">
                <div tw="flex items-center justify-between px-4">
                  <h2 tw="text-lg font-medium text-gray-900">Filters</h2>
                  <button
                    type="button"
                    tw="-mr-2 flex h-10 w-10 items-center justify-center p-2 text-gray-400 hover:text-gray-500"
                    onClick={() => setMobileFiltersOpen(false)}
                  >
                    <span tw="sr-only">Close menu</span>
                    <XMarkIcon tw="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <form tw="mt-4">
                  <div tw="border-t border-gray-200 py-4">
                    <h3 tw="sr-only">Categories</h3>
                    <div tw="px-4 pt-4 pb-2">
                      <div tw="space-y-6">
                        {categories.map((option, optionIdx) => (
                          <div key={option.value} tw="flex items-center">
                            <input
                              onChange={handleFilterChange}
                              id={`category-${optionIdx}`}
                              name="category"
                              defaultValue={option.value}
                              checked={
                                filters &&
                                filters.some(s =>
                                  s.values.includes(option.value),
                                )
                              }
                              type="checkbox"
                              tw="h-4 w-4 rounded border-gray-300 shadow"
                            />
                            <label
                              htmlFor={`filter-category-${optionIdx}`}
                              tw="ml-3 text-sm text-gray-500"
                            >
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  {baseFilters.map(section => (
                    <Disclosure
                      as="div"
                      defaultOpen={true}
                      key={section.name}
                      tw="border-t border-gray-200 pt-4 pb-4"
                    >
                      {({ open }) => (
                        <fieldset>
                          <legend tw="w-full px-2">
                            <Disclosure.Button tw="flex w-full items-center justify-between p-2 text-gray-400 hover:text-gray-500">
                              <span tw="text-sm font-semibold text-gray-800">
                                {section.name}
                              </span>
                              <span tw="ml-6 flex h-7 items-center">
                                <ChevronDownIcon
                                  css={[
                                    open ? tw`-rotate-180` : tw`rotate-0`,
                                    tw`h-5 w-5 transform`,
                                  ]}
                                  aria-hidden="true"
                                />
                              </span>
                            </Disclosure.Button>
                          </legend>
                          <Disclosure.Panel tw="px-4 pt-4 pb-2">
                            <div tw="space-y-6">
                              {section.options.map((option, optionIdx) => (
                                <div key={option.value} tw="flex items-center">
                                  <input
                                    onChange={handleFilterChange}
                                    id={`${section.id}-${optionIdx}-mobile`}
                                    name={section.id}
                                    defaultValue={option.value}
                                    checked={
                                      filters &&
                                      filters.some(s =>
                                        s.values.includes(option.value),
                                      )
                                    }
                                    type="checkbox"
                                    tw="h-4 w-4 rounded border-gray-300 shadow"
                                  />
                                  <label
                                    htmlFor={`${section.id}-${optionIdx}-mobile`}
                                    tw="ml-3 text-sm text-gray-500"
                                  >
                                    {option.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </fieldset>
                      )}
                    </Disclosure>
                  ))}
                  {stores.length > 0 && (
                    <div tw="border-t border-gray-200 pt-4 pb-4">
                      <div tw="text-sm font-semibold text-gray-800 w-full px-4 pt-4 pb-2">
                        Nearby dispensaries
                      </div>
                      <div tw="pt-4 pb-2 px-4 text-xs text-gray-500">
                        <ul tw="space-y-6">
                          {stores.map(store => (
                            <li key={store.label}>
                              <button
                                onClick={() => handleFilterClick(store.value)}
                                className="group"
                                type="button"
                                tw="flex items-center space-x-2 text-left"
                              >
                                <div tw="flex-shrink-0">
                                  <Image
                                    rounded
                                    src={store.fileName}
                                    photo={store.photo}
                                    alt={`${store.name} logo`}
                                    tw="h-6 w-6"
                                  />
                                </div>
                                <div tw="min-w-0 flex-1">
                                  <p tw="truncate text-gray-800 group-hover:underline">
                                    {store.label}
                                  </p>
                                  <p tw="truncate">
                                    {store.city}, {store.state} •{' '}
                                    {store.distance} mi
                                  </p>
                                </div>
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      <aside tw="hidden desktop:block">
        <form>
          <div tw="pb-6 border-b border-gray-200">
            <h3 tw="sr-only">Categories</h3>
            <div tw="space-y-4">
              {categories.map((option, optionIdx) => (
                <div key={option.value} tw="flex items-center">
                  <input
                    onChange={handleFilterChange}
                    id={`filter-category-${optionIdx}`}
                    name="category"
                    defaultValue={option.value}
                    checked={
                      filters &&
                      filters.some(s => s.values.includes(option.value))
                    }
                    type="checkbox"
                    tw="h-4 w-4 rounded border-gray-300 shadow"
                  />
                  <label
                    htmlFor={`filter-category-${optionIdx}`}
                    tw="ml-3 text-sm text-gray-600"
                  >
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
          {baseFilters.map((section, sectionIdx) => (
            <Disclosure
              as="div"
              defaultOpen={true}
              key={section.name}
              tw="border-b border-gray-200 py-6"
            >
              {({ open }) => (
                <Fragment>
                  <h3 tw="-my-3 flow-root">
                    <Disclosure.Button tw="flex w-full items-center justify-between py-3 text-sm text-gray-400 hover:text-gray-500">
                      <span tw="font-semibold text-gray-800">
                        {section.name}
                      </span>
                      <span tw="ml-6 flex items-center">
                        {open ? (
                          <MinusIcon tw="h-5 w-5" aria-hidden="true" />
                        ) : (
                          <PlusIcon tw="h-5 w-5" aria-hidden="true" />
                        )}
                      </span>
                    </Disclosure.Button>
                  </h3>
                  <Disclosure.Panel tw="py-6">
                    <div tw="space-y-4">
                      {section.options.map((option, optionIdx) => (
                        <div key={option.value} tw="flex items-center">
                          <input
                            onChange={handleFilterChange}
                            id={`filter-${section.id}-${optionIdx}`}
                            name={section.id}
                            defaultValue={option.value}
                            checked={
                              filters &&
                              filters.some(s => s.values.includes(option.value))
                            }
                            type="checkbox"
                            tw="h-4 w-4 rounded border-gray-300 shadow"
                          />
                          <label
                            htmlFor={`filter-${section.id}-${optionIdx}`}
                            tw="ml-3 text-sm text-gray-600"
                          >
                            {option.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </Disclosure.Panel>
                </Fragment>
              )}
            </Disclosure>
          ))}
          {stores.length > 0 && (
            <Fragment>
              <h3 tw="text-sm font-semibold text-gray-800 pt-6 pb-4">Nearby</h3>
              <ul tw="space-y-4 border-b border-gray-200 pb-6 text-xs text-gray-500">
                {stores.map(store => (
                  <li key={store.label}>
                    <button
                      onClick={() => handleFilterClick(store.value)}
                      className="group"
                      type="button"
                      tw="flex items-center space-x-2 text-left"
                    >
                      <div tw="flex-shrink-0">
                        <Image
                          rounded
                          alt={`${store.name} logo`}
                          photo={store.photo}
                          src={store.fileName}
                          tw="h-6 w-6"
                        />
                      </div>
                      <div tw="min-w-0 flex-1">
                        <p tw="truncate text-gray-800 group-hover:underline">
                          {store.label}
                        </p>
                        <p tw="truncate">
                          {store.city}, {store.state} • {store.distance} mi
                        </p>
                      </div>
                    </button>
                  </li>
                ))}
              </ul>
            </Fragment>
          )}
        </form>
      </aside>
    </Fragment>
  )
}

export default Filters

/** @jsxImportSource @emotion/react */

import { useState } from 'react'
import 'twin.macro'
import { Modal } from 'react-responsive-modal'
import { useLocalStorage } from 'react-use'
import toast from 'react-hot-toast'

import Button from './Button'
import Toast from './Toast'

const AgeConfirmation = props => {
  const [open, setOpen] = useState(true)
  const [checked, setChecked] = useState(true)
  const [, setAgeConfirmation] = useLocalStorage('fj-ageConfirmation')

  const onSubmit = e => {
    e.preventDefault()

    setOpen(false)

    if (checked) setAgeConfirmation(new Date().getTime())
  }

  const handleChange = () => {
    setChecked(!checked)
  }

  return (
    <Modal {...props} open={open}>
      <form onSubmit={onSubmit}>
        <h1 tw="font-bold text-xl">
          Are you at least 21 years old or a valid medical patient?
        </h1>
        <div tw="grid grid-cols-1 gap-y-2 tablet:grid-cols-2 tablet:(gap-x-2 gap-y-0 grid-cols-2) my-6">
          <Button type="submit">Yes</Button>
          <Button
            type="button"
            onClick={() => {
              toast.custom(t => (
                <Toast
                  content="You must be at least 21 years old or a valid medical patient to use FreshJane"
                  name="error"
                  title="Hold up!"
                  {...t}
                />
              ))
            }}
            outline
          >
            No
          </Button>
        </div>
        <label tw="flex items-center text-gray-600 text-sm">
          <input type="checkbox" checked={checked} onChange={handleChange} />
          <span tw="ml-2">
            <span tw="font-bold">Remember me</span> for 30 days
          </span>
        </label>
      </form>
    </Modal>
  )
}

export default AgeConfirmation

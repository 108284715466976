/** @jsxImportSource @emotion/react */

import 'twin.macro'

const Tooltip = props => (
  <span className="group" tw="relative leading-none">
    <span tw="pointer-events-none absolute -top-8 left-1/2 -translate-x-1/2 whitespace-nowrap rounded bg-black px-2 py-1 text-sm text-white hidden transition before:absolute before:left-1/2 before:top-full before:-translate-x-1/2 before:border-4 before:border-transparent before:border-t-black before:content-[''] group-hover:inline-block">
      {props.text}
    </span>
    {props.children}
  </span>
)

export default Tooltip

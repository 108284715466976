/** @jsxImportSource @emotion/react */

import { Fragment, useEffect, useState } from 'react'
import 'twin.macro'
import {
  Badge,
  Bold,
  CategoryBar,
  Card,
  ColGrid,
  List,
  ListItem,
  Title,
  Text,
  LineChart,
} from '@tremor/react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import {
  CircleStackIcon,
  ClockIcon,
  CalendarDaysIcon,
  TagIcon,
} from '@heroicons/react/24/outline'

import { dataFormatter } from '../utils'

dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)

export default function Details({
  activePriceIdx,
  clearFilters,
  result,
  setFilter,
  setSearchTerm,
}) {
  const [average, setAverage] = useState(0)
  const [current, setCurrent] = useState(0)
  const [difference, setDifference] = useState(0)
  const [history, setHistory] = useState([])

  const status = () => {
    if (difference <= 40) {
      return <span tw="text-secondary">low</span>
    }

    if (difference >= 60) {
      return <span tw="text-red-500">high</span>
    }

    return 'typical'
  }

  useEffect(() => {
    setHistory(
      result.history.raw.map(e => {
        const { price, updatedAt } = JSON.parse(e)

        return {
          price: price.map(p => p.rec || p),
          updatedAt,
        }
      }),
    )
  }, [result])

  useEffect(() => {
    setAverage(
      Number(
        result.recAverages.raw[activePriceIdx] ||
          result.medAverages.raw[activePriceIdx],
      ),
    )
  }, [activePriceIdx, result])

  useEffect(() => {
    if (history.length) {
      setCurrent(Number(history[history.length - 1].price[activePriceIdx]))
    }
  }, [activePriceIdx, history])

  useEffect(() => {
    if (average && current) {
      setDifference(50 + ((((average - current) / average) * 100) / 2) * -1)
    }
  }, [average, current])

  return (
    <Fragment>
      <Card>
        <div class="tremor-base tr-relative tr-flex tr-w-full tr-text-gray-500 tr-mb-2 tr-h-5 tr-text-sm">
          <div
            class="tr-flex tr-items-center tr-justify-end"
            style={{ width: '40%' }}
          >
            <span class="tr-block tr-left-1/2 tr-translate-x-1/2">
              {dataFormatter(average - average * 0.4)}
            </span>
          </div>
          <div
            class="tr-flex tr-items-center tr-justify-end"
            style={{ width: '20%' }}
          >
            <span class="tr-block tr-left-1/2 tr-translate-x-1/2">
              {dataFormatter(average + average * 0.4)}
            </span>
          </div>
          <div
            class="tr-flex tr-items-center tr-justify-end"
            style={{ width: '40%' }}
          ></div>
        </div>
        <CategoryBar
          categoryPercentageValues={[40, 20, 40]}
          colors={['emerald', 'yellow', 'red']}
          percentageValue={difference}
          tooltip={result.recPrices.raw[activePriceIdx]}
          marginTop="mt-2"
          showLabels={false}
        />
        <Text marginTop="mt-4">
          The current price is <Bold>{status()}</Bold> for this product
        </Text>
      </Card>
      <ColGrid numColsMd={2} gapX="gap-x-4" gapY="gap-y-4" marginTop="mt-4">
        <Card>
          <Title>Price history</Title>
          <LineChart
            data={result.history.raw.map(e => {
              const { price, updatedAt } = JSON.parse(e)

              return {
                Date: dayjs(updatedAt).format('MM/DD'),
                ...Object.fromEntries(
                  (result.weight.raw || ['each']).map((w, idx) => [
                    w,
                    price.map(p => p.rec || p)[idx],
                  ]),
                ),
              }
            })}
            dataKey="Date"
            categories={result.weight.raw || ['each']}
            valueFormatter={dataFormatter}
            showLegend={false}
            yAxisWidth="w-12"
            height="h-60"
          />
        </Card>
        <Card>
          <Title>Product details</Title>
          <List marginTop="mt-2">
            <ListItem>
              <Text>
                <div tw="mt-2 inline-flex items-center">
                  <ClockIcon
                    tw="mr-1 h-4 w-4 flex-shrink-0"
                    aria-hidden="true"
                  />
                  <span>Last updated</span>
                </div>
              </Text>
              <Text>
                <Bold>{dayjs(result.lastUpdated.raw).fromNow()}</Bold>
              </Text>
            </ListItem>
            <ListItem>
              <Text>
                <div tw="mt-2 inline-flex items-center">
                  <CalendarDaysIcon
                    tw="mr-1 h-4 w-4 flex-shrink-0"
                    aria-hidden="true"
                  />
                  <span>First tracked</span>
                </div>
              </Text>
              <Text>
                <Bold>
                  {dayjs(JSON.parse(result.history.raw[0]).updatedAt).fromNow()}
                </Bold>
              </Text>
            </ListItem>
            <ListItem>
              <Text>
                <div tw="mt-2 inline-flex items-center">
                  <CircleStackIcon
                    tw="mr-1 h-4 w-4 flex-shrink-0"
                    aria-hidden="true"
                  />
                  <span>Data source</span>
                </div>
              </Text>
              <Text>
                <Bold>
                  {new URL(result.url.raw).hostname.replace('www.', '')}
                </Bold>
              </Text>
            </ListItem>
            <ListItem>
              <Text>
                <div tw="mt-2 inline-flex items-center">
                  <TagIcon tw="mr-1 h-4 w-4 flex-shrink-0" aria-hidden="true" />
                  <span>Additional tags</span>
                </div>
              </Text>
            </ListItem>
          </List>
          <div tw="flex flex-wrap">
            {[
              result.medOnly.raw && (
                <button
                  tw="m-0.5"
                  type="button"
                  onClick={() => {
                    setFilter('options', 'med')
                    clearFilters(['latLng', 'options', 'radius'])
                    setSearchTerm('', { shouldClearFilters: false })
                  }}
                >
                  <Badge color="emerald" text="medical" />
                </button>
              ),
              ...(
                (result.category.raw && result.category.raw.filter(c => c)) ||
                []
              ).map(tag => (
                <button
                  tw="m-0.5"
                  type="button"
                  onClick={() => {
                    setFilter('category', tag)
                    clearFilters(['category', 'latLng', 'radius'])
                    setSearchTerm('', { shouldClearFilters: false })
                  }}
                >
                  <Badge color="emerald" text={tag} />
                </button>
              )),
              ...(
                (result.strain.raw && result.strain.raw.filter(s => s)) ||
                []
              ).map(tag => (
                <button
                  tw="m-0.5"
                  type="button"
                  onClick={() => {
                    setFilter('strain', tag)
                    clearFilters(['latLng', 'radius', 'strain'])
                    setSearchTerm('', { shouldClearFilters: false })
                  }}
                >
                  <Badge color="emerald" text={tag} />
                </button>
              )),
            ]}
          </div>
        </Card>
      </ColGrid>
    </Fragment>
  )
}

/** @jsxImportSource @emotion/react */

import 'twin.macro'
import { Redirect, useParams } from 'react-router-dom'

const AutoCompleteRedirect = () => {
  const { key, value } = useParams()

  return (
    <Redirect
      to={{
        pathname: '/search',
        state: {
          filters: [
            {
              field: `${key}[]`,
              values: [value],
              type: 'all',
            },
          ],
        },
      }}
    />
  )
}

export default AutoCompleteRedirect

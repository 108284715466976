/** @jsxImportSource @emotion/react */

import { Fragment, useState } from 'react'
import 'twin.macro'
import { geocodeByLatLng } from 'react-google-places-autocomplete'
import toast from 'react-hot-toast'

import { MapPinIcon } from '@heroicons/react/24/outline'

import Toast from './Toast'
import Tooltip from './Tooltip'

const GeoLocate = ({ loading: parentLoading, setSessionLocation }) => {
  const [loading, setLoading] = useState(false)

  const getLocation = () => {
    if (navigator.geolocation) {
      setLoading(true)

      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords

          if (latitude && longitude) {
            geocodeByLatLng({ lat: latitude, lng: longitude }).then(results => {
              setSessionLocation({
                label: results.filter(r => !r.types.includes('plus_code'))[0]
                  .formatted_address,
                value: { latitude, longitude },
              })
              toast.custom(t => (
                <Toast
                  content="Your location has been updated"
                  name="success"
                  title="Location updated"
                  {...t}
                />
              ))
              setLoading(false)
            })
          }
        },
        error => {
          toast.custom(t => (
            <Toast
              content="There was a problem updating your location"
              name="error"
              title="Location error"
              {...t}
            />
          ))
          setLoading(false)
        },
      )
    } else {
      toast.custom(t => (
        <Toast
          content="There was a problem updating your location"
          name="error"
          title="Location error"
          {...t}
        />
      ))
      setLoading(false)
    }
  }

  return (
    <Fragment>
      {loading ? (
        <svg
          tw="animate-spin h-5 w-5 text-primary"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            tw="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            tw="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      ) : (
        <Tooltip text="Use precise location">
          <button
            onClick={() => getLocation()}
            type="button"
            className="group"
            tw="text-primary"
          >
            <MapPinIcon tw="h-6 w-6 group-hover:text-green-700" />
          </button>
        </Tooltip>
      )}
    </Fragment>
  )
}

export default GeoLocate

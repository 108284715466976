/** @jsxImportSource @emotion/react */

import tw from 'twin.macro'
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline'

const theme = {
  error: {
    icon: ExclamationCircleIcon,
    styles: tw`text-red-400`,
  },
  success: {
    icon: CheckCircleIcon,
    styles: tw`text-green-400`,
  },
  warning: {
    icon: ExclamationTriangleIcon,
    styles: tw`text-yellow-400`,
  },
}

const IconComponent = ({ name = 'success', ...props }) => {
  let Icon = theme[name].icon
  return <Icon {...props} css={theme[name].styles} />
}

export default IconComponent
